import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Popover,
  Typography,
  Card,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import QuickChatController, {
  Props,
  configJSON,
} from "./QuickChatController.web";
import { liveChatCustomStyles } from "../../../components/src/LiveChatStyles.web";
import { withStyles } from "@material-ui/core/styles";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import {
  DoubleTick,
  close,
  doubleTickGray,
  externalImage,
  minus,
  noQuickChat,
  quickChatIcon,
} from "./assets";
import InfiniteScroll from "react-infinite-scroll-component";
import { Chat, GroupedMessages } from "../../../components/src/ReusableEnums";
import ChatImagePreview from "../../../components/src/ChatImagePreview.web";
import NewChatModal from "../../../components/src/NewChatModal.web";
import { formatChatDate, formatLiveChatTime, renderContent, renderMedia } from "../../../components/src/ReusableFunctions";
import { Link } from "react-router-dom";
import NewChatModalWeb from "./NewChatModal.web";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// Customizable Area End

export class QuickChat extends QuickChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      ...this.state,
      isChatCollapsed: false,
    };
    // Customizable Area End
  }

  // Customizable Area Start
  ChatCollapse = () => {
    this.setState({
      isChatCollapsed: true,
      openSingleChat: null,
      quickItemData:{id:"", attributes:{}}
    });
  };

  handleMinimize = () => {
    this.setState((prevState) => ({
      isChatCollapsed: true,
      openSingleChat: null,
      isChatMinize: !prevState.isChatMinize,
    }));
  }

 renderSigleChatModal = () => {
  const { classes } = this.props;
  const {allSinglePersonChat, openSingleChat, quickItemData } = this.state;
    return (
      <Popover
        open={Boolean(openSingleChat)}
        anchorEl={openSingleChat}
        onClose={this.ChatCollapse}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{ paper: `${classes.sinlgeChatModal}` }}
        data-test-id="ChatCollapse"
      >
        <Box>
          <Box className={classes.quickChatHeader}>
            <Box className="etoh-header-quick-chat">
              {renderContent(quickItemData.attributes.chat_type,quickItemData.attributes.name.charAt(0).toUpperCase())}
              <Typography className="user-name">
                {quickItemData.attributes.name}
              </Typography>
            </Box>
            <Box className="single-chat-icons">
              <Box
                className="close-icon"
                onClick={this.handleMinimize}
                data-test-id="isChatCollapsed"

              >
                <img src={minus} alt="minus" />
              </Box>
              <Box
                className="close-icon"
                onClick={this.closeSingleChatEvent}
                data-test-id="closeNewChat"
              >
                <img src={close} alt="closeGray" />
              </Box>
            </Box>
          </Box>
         <>
          <Box id="parentBoxID" style={{ maxHeight: 287, width: 350, overflow: "auto" }}>
          {allSinglePersonChat && this.state.isLoading && (
              <Box className="loader">
              <Typography>
              {this.translateQChat(`${configJSON.loadingTxt}`)}
              </Typography>
            </Box>
            )}
          {allSinglePersonChat && (
          <InfiniteScroll
            dataLength={allSinglePersonChat.length}          
            next={this.fetchMoreDataEvent}
            hasMore={this.state.hasNextPageEvent}
            loader={ !allSinglePersonChat.length && (
                <Box className="loader" key="loader">
                  <Typography>
                    {this.translateQChat(`${configJSON.loadingTxt}`)}
                  </Typography>
                </Box>
              )
            }            
            scrollableTarget="parentBoxID"
            data-test-id="InfiniteScroll"
          >
            <Box className="drawerContent">
              <Box className="profileQuickChatBlockOuter">
                <Box className="chat-block-wrapper">
                  {this.state.singleMessage?.map((group: GroupedMessages) => (
                    <Box key={group.date}>
                      <Typography className="date-message">
                        {group.date}
                      </Typography>
                      {group.messages.map((item:Chat) => {   
                            const senderData = item.attributes.sender?.data;
                            const currentUserId = this.state.currentUserId;   
                            const isOutgoingMessage = senderData?.id === currentUserId;                  
                         return (
                          <Box key={item.id}>
                            <Box
                              className={
                                isOutgoingMessage
                                  ? "chat-text-content-sender"
                                  : "chat-text-content-reciever"
                              }
                            >
                              <Box className="right-content-quick">
                                {item.attributes.message ? (
                                  <Typography className="help-message-quick">
                                    {item?.attributes?.media_file &&
                                      renderMedia(
                                        item.attributes.media_file,
                                        item.attributes.media_type,
                                        this.fullScreenImgModelOpen
                                      )}
                                    {item?.attributes?.message}
                                  </Typography>
                                ) : item.attributes.media_file && (
                                  <Typography className="help-message-quick">
                                    {renderMedia(
                                      item?.attributes?.media_file,
                                      item?.attributes?.media_type,
                                      this.fullScreenImgModelOpen
                                    )}
                                  </Typography>
                                )}
                                <Typography className="tick-time-quick">
                                  {formatLiveChatTime(item?.attributes?.created_at)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  ))}
                  <div ref={this.chatEndRef} />                   
                </Box>
              </Box>
            </Box>
          </InfiniteScroll> 
          )}                         
          </Box>
          <Box className="send-quick-messge-area">
            <ChatImagePreview
              handleCloseAttachment={this.handleCloseAttachmentEvent}
              previews={this.state.preview}
              filesUploaded={this.state.fileUploaded}
              translateChat={this.translateQChat}
              chatTxtMessage={this.state.chatTxtMessage}
              handleAllInputChange={this.handleChatInputChange}
              onBrowse={this.onBrowseEvent}
              handleKeyDown={this.handleKeyDown}
              formRef={this.formRef}
              fileRef={this.fileRef}
              handleSubmitMessage={this.handleSubmitMessage}
              createExternalAndInternalChatMessgaeEvent={
                this.createExternalAndInternalChatMessgaeEvent
              }
              chatType={quickItemData.attributes.chat_type}
              handleOnDrop={this.handleOnDropEvent}
              messageId={this.state.messageId}
              data-test-id="ChatImagePreview"
            />
          </Box>
          <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={this.state.fullScreenImgURL?true:false}
          onClose={this.fullScreenImgModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.fullScreenImgURL?true:false}>
            <div className={classes.paper} style={{ width:"50%", height:"90%" }}>
              <img src={this.state.fullScreenImgURL} width={"100%"} height={"100%"} alt="Image" />
            </div>
          </Fade>
        </Modal>
          {this.displayDataErrorMessage()}
          {this.displayApiErrorMessage()}
          </>
        </Box>
      </Popover>
    );
  };
  displayDataErrorMessage = () => {
    return(
      <Box style={{display: 'none'}}>
        <Typography>No data found.</Typography>
      </Box>
    );
  }

  displayApiErrorMessage = () => {
    return(
      <Box style={{display: 'none'}}>
        <Typography>Api response failed.</Typography>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, anchorQuickChatEl, handleQuickChatPopoverClose } =
      this.props;
    const { quickChatData, openSingleChat, isChatMinize, quickItemData } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Popover
          open={Boolean(anchorQuickChatEl)}
          anchorEl={anchorQuickChatEl}
          onClose={handleQuickChatPopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{ paper: `${classes.quickChatBox}` }}
        >
          <Card>
            <Box className="quick-chat-container">
              <Box>
                <Typography variant="h6" className="title">
                  {this.translateQChat(`${configJSON.chatConversationsTxt}`)}
                  <Link to="/live-chat">
                  <img 
                    className="icon"
                    src={quickChatIcon}
                    alt="quickChatIcon"
                  />
                  </Link>
                </Typography>
                <Typography variant="body2" className="sub-title">
                  {this.translateQChat(`${configJSON.chatSubtext}`)}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="text"
                  className="btn-new-chat"
                  data-test-id="openNewChatEvent"
                  onClick={this.openNewChatEvent}
                >
                  + {this.translateQChat(`${configJSON.newChatBtn}`)}
                </Button>
              </Box>
              <Box></Box>
            </Box>
            <Box id="parentBox" className="quickChatParentBox">
              {quickChatData && quickChatData.length > 0 ? (
                <InfiniteScroll
                  dataLength={quickChatData.length}
                  next={this.fetchMoreData}
                  hasMore={false}
                  loader={
                    <Box className="loader" key="loader">
                      <Typography>
                        {this.translateQChat(`${configJSON.loadingTxt}`)}
                      </Typography>
                    </Box>
                  }
                  className="list"
                  scrollableTarget="parentBox"
                >
                  <Box className="drawerContent">
                    <Box className="profileBlockOuter">
                      <Box>
                        <Box className="chat-block-wrapper">
                          {quickChatData.map((item: Chat) => {
                            const chatName = item.attributes.name.split(" ");
                            const nameInitials = chatName
                              ?.map((item: string) =>
                                item.charAt(0).toUpperCase()
                              )
                              .join("");
                            return (
                              <Box
                                className="etoh-header-chat"
                                key={item.id}
                                data-test-id={`openSingleChatEvent-${item.id}`}
                                onClick={(
                                  event: React.MouseEvent<HTMLElement>
                                ) => this.openSingleChatEvent(event, item)}
                              >
                                {item.attributes.chat_type === "external" ? (
                                  <Box className="etoh-name">                                   
                                    <img
                                      src={externalImage}
                                      alt="brows"
                                    ></img>
                                  </Box>
                                ) : renderContent(item.attributes.chat_type,nameInitials)
                                }
                                <Box className="etoh-content">
                                  <Typography className="support-txt">
                                    {item.attributes.name}
                                  </Typography>
                                  <Typography data-test-id="message-help" className="support-sub-txt">
                                    {item.attributes.last_message.data &&
                                      item.attributes.last_message.data
                                        .attributes.message}
                                  </Typography>
                                </Box>
                                <Box className="time-content">
                                  <Typography className="chat-time">
                                    {item.attributes.last_message.data &&
                                    formatChatDate(item.attributes.last_message.data.attributes.created_at)}
                                  </Typography>
                                  {this.handleNotification(item,this.state.currentUserId)? (
                                     <Box className="chat-count">
                                     <Typography  className="chat-count-position">
                                        {item.attributes.unread_count}
                                      </Typography>
                                      </Box>  
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </InfiniteScroll>
              ) : (
                <Box className="default-chat">
                  <img className="image" src={noQuickChat} alt="noQuickChat" />
                  <Typography className="title">
                    {this.translateQChat(`${configJSON.defaultText}`)}
                  </Typography>
                  <Typography className="sub-title">
                    {this.translateQChat(`${configJSON.subText}`)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Card>
        </Popover>
        {openSingleChat && this.renderSigleChatModal()}
        {isChatMinize && 
         <Box className={classes.btnMinimizeForm} onClick={(event: any) => {
          this.openSingleChatEvent(event, quickItemData);
          this.setState({isChatMinize: false});
          }}>
        <Box className={classes.quickChatHeader} >
            <Box className="etoh-header-quick-chat">
              {renderContent(quickItemData.attributes.chat_type,quickItemData.attributes.name.charAt(0).toUpperCase())}
              <Typography className="user-name">
                {quickItemData.attributes.name}
              </Typography>
            </Box>
            <Box className="single-chat-icons">
              <Box
                className="close-icon"
                onClick={this.closeSingleChatEvent}
                data-test-id="closeNewChat"
              >
                <img src={close} alt="closeGray" />
              </Box>
            </Box>
          </Box>
          </Box>
        }
        {this.state.openQuickNewChat && (
           <NewChatModalWeb openModal={this.state.openQuickNewChat} closeModal={this.closeNewChatQuickEvent} navigation={""} classes={classes}/>          
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(liveChatCustomStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(QuickChat)
);
// Customizable Area End
